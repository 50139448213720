
import { defineNuxtRouteMiddleware } from "#app";
import { useDraftsStore, usePageStore } from "#imports";

/**
 * Resets any stores
 */
export default defineNuxtRouteMiddleware(async () => {
  useDraftsStore().$reset();
  usePageStore().$reset();
});